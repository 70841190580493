import React, { useState, useEffect } from "react"
import { isBrowser } from "../services/auth"
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import { useQuery } from "@apollo/client"
import Select from "react-select"
import API from "../services/api"
import { getUser } from "../services/auth"
import getUserRole from "../query/userRoles"
import dropdownOptionsQuery from "../query/dropdownOptions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { isLoggedIn } from "../services/auth"
import CreateDFXModelForm from "../components/dfxModel/CreateDFXModelForm"
import * as styles from "../css/index.module.css"

const Home = props => {
  // console.log(props)
  if (!isLoggedIn()) {
    // navigate(`/app/login`)
    isBrowser() && navigate(`/app/login`)
    // window.location.replace("/app/login")
  }
  const [userID, setUserID] = useState(null)
  const [userRoleName, setUserRoleName] = useState("")
  const [threeDobject, setThreeDobject] = useState({})
  const [cobieType, setCobieType] = useState({})

  // useEffect(() => {
  //   setUserID(getUser().user ? getUser().user.id : null)
  // }, [])

  // const [
  //   userRole,
  //   { loading: userRoleLoading, error: userRoleError, data: userRoleData },
  // ] = useLazyQuery(getUserRole)

  // useEffect(() => {
  //   if (userID) {
  //     console.log(userID)
  //     userRole({
  //       variables: {
  //         userId: userID,
  //       },
  //     })
  //   }
  // }, [userID])

  // const [dDOption, { loading, error, data }] =
  //   useLazyQuery(dropdownOptionsQuery)

  const { loading, error, data } = useQuery(dropdownOptionsQuery)

  // useEffect(() => {
  //   if (userRoleData) {
  //     console.log(userRoleData.user.roles.nodes[0].name)
  //     setUserRoleName(userRoleData.user.roles.nodes[0].name)
  //     dDOption()
  //   }
  // }, [userRoleData, dDOption])

  let dfxModelOptions = []
  let cobieTypeOptions = []

  if (data) {
    dfxModelOptions = data.dFXModels.nodes.map(node => {
      return {
        label: node.dfxModelCustomFields.title,
        value: node.databaseId,
      }
    })

    cobieTypeOptions = data.cobie.map(type => {
      return {
        label: type.cobie_type,
        value: type.cobie_type,
      }
    })
  }

  const pairButtonHandler = async () => {
    console.log(threeDobject.value)
    console.log(cobieType.value)

    const { data } = await API.post("sce/dfx-model/pair", {
      dfxModelId: threeDobject.value,
      cobieType: cobieType.value,
    })

    console.log(data)
  }

  if (loading) {
    return (
      <div className={styles.loaderCenter}>
        <CircularProgress />
        <br />
        <h2>Please wait while we are making things ready for you...</h2>
      </div>
    )
  }
  return (
    <Layout classes={styles.home}>
      <div className={styles.dropdownContainer}>
        <div className={styles.d1}>
          <Select
            isSearchable
            options={dfxModelOptions}
            onChange={(option, action) => setThreeDobject(option)}
          />
        </div>
        <div className={styles.pairButton}>
          <button onClick={pairButtonHandler}>Pair</button>
        </div>
        <div className={styles.d2}>
          <Select
            isSearchable
            options={cobieTypeOptions}
            onChange={(option, action) => setCobieType(option)}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Home
