import { gql } from "@apollo/client"

const dropdownOptionsQuery = gql`
  query dropdownOptionsQuery {
    dFXModels {
      nodes {
        databaseId
        title
        slug
        dfxModelCustomFields {
          id
          title
          type
          description
          cobieFieldType
          prefab
          price
          sku
          thumbnail {
            link
          }
          glbFile {
            link
          }
        }
      }
    }
    cobie {
      cobie_type
    }
  }
`


export default dropdownOptionsQuery
